import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import skySvg from '../assets/images/bg_sky.png';
import clouds from '../assets/images/clouds.svg';
import greenaryPng from '../assets/images/bg_ground.png';
import infoIcon from '../assets/images/info-icon.svg';
import diceSvg from '../assets/images/dice.svg';
import furu from '../assets/images/furu_bg.png';
import DiceRoll from '../components/DiceRoll';
import { LoadingSpinnerOverlay } from '../Spinner';
import PkScreen from '../components/PkScreen';
import Football from '../assets/images/football.svg';
import { getSugorokuMaster } from '../api/getSugorokuMaster';
import SugorokuMasterManager from '../lib/sugorokuMasterManager';
import { getAccountSugorokuProgress } from '../api/getAccountSugorokuProgress';
import { waitAsyncFunctionExecuted } from '../lib/asyncUtils';
import { postUseDice } from '../api/postUseDice';
import { SugorokuErrorDialog } from '../components/SugorokuErrorDialog';
import UseDiceResult from '../types/useDiceResult';
import SugorokuHelp from '../components/SugorokuHelp';
import { handleAxiosError } from '../lib/handleErrorResponse';
import useLogout from '../hooks/useLogout';
import { useShowItemNoticeBadgeContext } from '../hooks/useShowNoticeBadgeContext';
import {
    SugorokuMaster,
    SugorokuStageEvent,
    SugorokuItem,
} from '../types/sugorokuMasterType';
import SugorokuItemManager from '../lib/sugorokuItemManager';
import FullTilePath from '../components/FullTilePath';
import SessionStorageManager from '../lib/sessionStorageManager';
import { SESSION_STORAGE_KEYS } from '../constants/sessionStorageKeys';
import { ga4PushEvent } from '../ga4';
import { GA4_CUSTOM_EVENT } from '../constants/ga4CustomEvent';
import { getEvents } from '../api/getEvents';
import EventType from '../types/event';
import {
    findRemainingTilesInEvent,
    fetchStageAreaName,
    fetchReceiveProbability,
    calculateAvailablePlayers,
    findNextStageEvent,
    calculateDaysRemaining,
} from '../lib/SugorokuGetInfoLogic';
import DynamicText from '../components/sugoroku/DynamicText';
import PkDialog from '../components/sugoroku/PkDialog';
import CheckinDialog from '../components/sugoroku/CheckinDialog';
import RegisterDialog from '../components/sugoroku/RegisterDialog';
import PlayerListButtonComponent from '../components/sugoroku/PlayerListButton';
import SaikoroContainerComponent from '../components/sugoroku/SaikoroContainer';
import { useNavigate } from 'react-router-dom';
import { isSugorokuActive } from '../lib/isSugorokuActive';
import { getSugorokuRanking } from '../api/getSugorokuRanking';
import SugorokuRankingInfo from '../components/sugoroku/SugorokuRankingInfo';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    min-height: calc(100dvh - 48px - 82px);
    margin: 0 auto;
    overflow: hidden;

    @media (height <= 755px) {
        min-height: 755px;
    }
`;

const SkyBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45%;
    overflow: hidden;
    background-image: url(${skySvg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

const moveCloudAnimation = keyframes`
    0% {
        transform: translateX(350px);
    }
    100% {
        transform: translateX(-120%);
    }
`;

const secondMoveCloudAnimation = keyframes`
    0% {
        transform: translateX(400px);
    }
    100% {
        transform: translateX(-180%);
    }
`;

const CloudsOverlay = styled.div`
    position: absolute;
    top: 50px;
    left: 50px;
    width: 35%;
    height: 14%;
    overflow: hidden;
    background-image: url(${clouds});
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${moveCloudAnimation} 140s linear infinite;
`;

const SecondCloudOverlay = styled.div`
    position: absolute;
    top: 135px;
    left: 150px;
    width: 46%;
    height: 34%;
    overflow: hidden;
    background-image: url(${clouds});
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${secondMoveCloudAnimation} 90s linear infinite;
`;

const GreenaryBackground = styled.div`
    position: absolute;
    bottom: -10%;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 65%;
    background-image: url(${greenaryPng});
    background-position: center;
    background-size: cover;
`;

const MainContentContainer = styled.div`
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
`;

const CircularRectangleBoxContainer = styled.div`
    position: relative;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 290px;
    height: 62px;
    background-color: white;
    border-radius: 0 0 49px;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 16%);
`;

const InfoIcon = styled.img<{
    $ableToPush: boolean;
}>`
    position: absolute;
    top: 8px;
    right: 20px;
    z-index: 5;
    width: 31px;
    height: 31px;
    pointer-events: ${(props) => (props.$ableToPush ? 'auto' : 'none')};
    cursor: pointer;
    filter: drop-shadow(1px 1px 3px rgb(0 0 0 / 22.4%));
    border: 3px solid #000000;
    border-radius: 50%;
    opacity: ${(props) => (props.$ableToPush ? 1 : 0.3)};
`;

const DiceImage = styled.img`
    width: 50px;
    height: 50px;
`;

const Furu = styled.img`
    width: 50px;
    height: 27px;
`;

const RoundButton = styled.button<{ $ableToPush: boolean }>`
    position: absolute;
    right: calc(50% - 50vw);
    bottom: 70px;
    z-index: 6;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 117px;
    height: 117px;
    font-size: 23px;
    font-weight: 900;
    color: #e40513;
    cursor: pointer;
    background-color: white;
    border: 2px solid rgb(0 0 0 / 60%);
    border-radius: 50%;
    ${(props) =>
        !props.$ableToPush &&
        `
        pointer-events: none;
    `}

    &::before {
        position: absolute;
        inset: 0;
        content: '';
        border: 5px solid #e40513;
        border-radius: 50%;
        ${(props) =>
            !props.$ableToPush &&
            `
            background-color: rgb(0, 0, 0, 30%);
            filter: brightness(0.7);
            pointer-events: none;
        `}
    }

    @media (width >= 360px) {
        right: calc(50% - 180px);
    }
`;

const TilePathAndFootballContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65%;

    @media (height <= 648px) {
        bottom: 60px;
    }
`;

const FootballImage = styled.img`
    position: absolute;
    top: -43px;
    left: 111px;
    z-index: 5;
    width: 45px;
    height: 45px;
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 30%));

    @media (height <= 700px) {
        top: -10px;
    }

    @media (height <= 652px) {
        top: 80px;
    }
`;

const StartDateMessageContainer = styled.div`
    position: absolute;
    top: 49%;
    left: 50%;
    z-index: 7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 215px;
    background-color: #595656;
    transform: translate(-50%, -50%);
`;

const StartDateMessageMainText = styled.span`
    font-size: 22px;
    font-weight: bold;
    color: white;
    text-align: center;
`;

const SugorokuDisabledMessageContainer = styled(StartDateMessageContainer)`
    font-size: 20px;
`;

interface SeasonData {
    season_id: number;
    season_name: string;
    progress: {
        total_cells: number;
        rank: number;
    };
}

const MyPageSugoroku: React.FC = () => {
    //TODO :initial値は０かnullだとエラー出たため1000
    const DEFAULT_CELLS_TO_GOAL = 1000;
    const [tilePosition, setTilePosition] = useState(0);
    const [tilePositionChange, setTilePositionChange] = useState(0);
    const [isRollingDice, setIsRollingDice] = useState(false);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [showDiceRollLoadingSpinner, setShowDiceRollLoadingSpinner] =
        useState(false);
    const [showPkScreen, setShowPkScreen] = useState(false);
    const [diceValue, setDiceValue] = useState(0);
    const [showDice, setShowDice] = useState(false);
    const [daysRemaining, setDaysRemaining] = useState(0);

    // NOTE: 通年地元巡り方式に戻すとなった場合、下記2つの値は維持しないといけない。
    const [stageAreaName, setStageAreaName] = useState('');
    const [nextEventName, setNextEventName] = useState<
        string | null | undefined
    >('');
    stageAreaName;
    nextEventName;
    // lint通す目的で残す。

    const [hasError, setHasError] = useState(false);
    const [diceCount, setDiceCount] = React.useState<number | null>(null);
    const [AvailablePlayers, setAvailablePlayers] = useState(0);
    const [AllPlayers, setAllPlayers] = useState(0);
    const [showPkDialog, setShowPkDialog] = useState(false);
    const [showHelpPage, setShowHelpPage] = useState(false);
    const [cellsToGoal, setCellsToGoal] = useState(DEFAULT_CELLS_TO_GOAL);
    const [isFinished, setIsFinished] = useState(false);
    const [isSugorokuStarted, setIsSugorokuStarted] = useState(true);
    const [nextEventItem, setNextEventItem] = useState<SugorokuItem | null>(
        null
    );
    const [remainingTiles, setRemainingTiles] = useState(1000);

    // NOTE: 通年地元巡り方式に戻すとなった場合、下記2つの値は維持しないといけない。
    const [remainingEventTiles, setRemainingEventTiles] = useState(0);
    const [remainDiceValue, setRemainDiceValue] = useState(0);
    // lint通す目的で残す。

    remainingEventTiles;
    remainDiceValue;
    const [currentRank, setCurrentRank] = useState<number | null>(null);
    const [totalPlayers, setTotalPlayers] = useState<number>(0);
    const [pastRankings, setPastRankings] = useState<
        Array<{
            sugoroku_map: string;
            rank: number | null;
            total_players: number;
        }>
    >([]);
    const [seasonData, setSeasonData] = useState<SeasonData[]>([]);
    const [currentTopRankings, setCurrentTopRankings] = useState<
        Array<{
            rank: number;
            total_cell: number;
        }>
    >([]);

    const handleRemainingTilesChange = (count: number) => {
        setRemainingTiles(count);
    };

    const isSugorokuDisabled =
        import.meta.env.VITE_IS_SUGOROKU_DISABLED === 'true';

    //最終ステージのis_finishedがtrueの場合、cellsToGoal値としてDEFAULT_CELLS_TO_GOAL表示されちゃうから０に設定にする
    if (cellsToGoal === DEFAULT_CELLS_TO_GOAL) {
        setCellsToGoal(0);
    }
    // フッターに「！」を表示するためのフラグを取得
    const [sugorokuMap, setSugorokuMap] = React.useState<SugorokuMaster | null>(
        null
    );
    const { setShowItemNoticeBadge } = useShowItemNoticeBadgeContext();
    const logout = useLogout();
    const [totalCells, setTotalCells] = useState<number>(0);
    const showPkDialogRef = useRef(showPkDialog);
    const [initialized, setInitialized] = useState(false);
    const [showCheckinDialog, setShowCheckinDialog] = useState(false);
    const [showRegisterDialog, setShowRegisterDialog] = useState(false);
    const [event, setEvent] = React.useState<EventType | undefined>(undefined);
    const [eventNames, setEventNames] = useState<string[]>([]);

    const navigate = useNavigate();

    /**
     * これまでに進んだマスと今回進むマスを足し合わせる
     * @param totalCells 総マス数
     * @param tilePosition 現在いるマス数
     * @param remainingTiles 残りのマス数
     * @returns たされたマス数
     */
    const calculateTraversedCells = (
        totalCells: number,
        tilePosition: number,
        remainingTiles: number
    ) => {
        if (remainingTiles === 2) {
            return tilePosition + 2;
        } else if (remainingTiles === 1) {
            if (tilePosition + 3 > totalCells) {
                return tilePosition + 2;
            } else {
                return tilePosition + 3;
            }
        } else {
            return tilePosition + 1;
        }
    };

    useEffect(() => {
        if (!initialized) {
            ga4PushEvent(GA4_CUSTOM_EVENT.DISPLAY_SUGOROKU_MAP_PAGE);
            fetchSugorokuMaster();
            fetchSugorokuRanking();
        }
        setInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialized]);

    useEffect(() => {
        showPkDialogRef.current = showPkDialog;
    }, [showPkDialog, cellsToGoal, remainingTiles]);

    useEffect(() => {
        // 現在時刻がすごろくの開始日時以降かつ終了日時以前である
        setIsSugorokuStarted(isSugorokuActive());
    }, [sugorokuMap]);

    const handleDiceRollComplete = (diceValue: number) => {
        setIsRollingDice(true);
        setDiceValue(diceValue);
        const positionChange = diceValue;
        setTilePositionChange(positionChange);
        setTimeout(() => {
            setTimeout(() => {
                setIsRollingDice(false);
                // 最新のshowPkDialogがTrueのときはダイスを非表示にしない
                // なぜこうしているかと言うと、不要なshowDiceの再代入によって再レンダリングがされてほしくないから
                if (!showPkDialogRef.current) {
                    setShowDice(false);
                }
            }, 1000);
        }, 3000);
    };

    const handleButtonClick = async () => {
        ga4PushEvent(GA4_CUSTOM_EVENT.PRESSED_ROLL_DICE_BUTTON);
        setShowDiceRollLoadingSpinner(true);
        try {
            const postUseDiceResult: UseDiceResult =
                await waitAsyncFunctionExecuted(postUseDice, 500);
            setShowDiceRollLoadingSpinner(false);
            setShowDice(true);
            setTimeout(() => {
                handleDiceRollComplete(postUseDiceResult.diceValue);
                if (diceCount !== null) {
                    setDiceCount(diceCount - 1);
                    if (diceCount - 1 === 0) {
                        setShowItemNoticeBadge(false);
                    }
                }
            }, 110);
        } catch (error) {
            console.error('Failed to roll dice:', error);
            setShowDiceRollLoadingSpinner(false);
            setHasError(true);
        }
    };

    interface AccountSugorokuProgress {
        current_stage_code: string;
        current_cell: number;
        cells_to_goal: number;
        items: {
            code: string;
            club_player_code: string;
            quantity: number;
        }[];
    }

    /**
     * アカウントのすごろく進捗から、目標までの残りのマス数を取得する
     * @param accountSugorokuProgress アカウントのすごろく進捗
     * @returns 目標までの残りのマス数
     */
    const getCellsToGoal = (
        accountSugorokuProgress: AccountSugorokuProgress | null
    ) => {
        if (typeof accountSugorokuProgress?.cells_to_goal === 'number') {
            return accountSugorokuProgress?.cells_to_goal ?? 0;
        }
        return 0;
    };

    /**
     * 次のイベントまでの残りのマス数を計算する
     * @param accountSugorokuProgress アカウントのすごろく進捗
     * @param nextStageEvent 次のイベント
     * @returns 次のイベントまでの残りのマス数
     */
    const findStageGoal = (
        accountSugorokuProgress: AccountSugorokuProgress,
        nextStageEvent: SugorokuStageEvent
    ) => {
        const nextGoal = nextStageEvent.cell;
        return nextGoal - accountSugorokuProgress.current_cell;
    };

    const handleHelpIconClick = () => {
        setShowHelpPage(true);
    };

    const handlePkDialogButtonClick = () => {
        setShowPkDialog(false);
        setShowPkScreen(true);
    };

    const fetchSugorokuMaster = async () => {
        setShowLoadingSpinner(true);
        // すごろくできない状態ならAPIコールする意味なし
        if (isSugorokuDisabled) {
            setShowLoadingSpinner(false);
            return;
        }
        try {
            // 期間外のすごろくマスタを削除
            SugorokuMasterManager.checkAndClearExpiredMaster();
            // 余り出目の取得
            const remainDiceValue =
                SessionStorageManager.getSessionStorageValue(
                    SESSION_STORAGE_KEYS.MY_PAGE_SUGOROKU_REMAIN_DICE_VALUE
                ) || 0;
            const remainDiceValueNumber = Number(remainDiceValue);
            setRemainDiceValue(remainDiceValueNumber);

            const response = await getSugorokuMaster();
            SugorokuMasterManager.setSugorokuMaster(response);
            const mapEvents = SugorokuMasterManager.getMap();
            setSugorokuMap(mapEvents);
            if (!mapEvents) {
                throw new Error('no maps returned');
            }
            const player = SugorokuMasterManager.getClubPlayers();
            const accountSugorokuProgress = await getAccountSugorokuProgress();
            if (!accountSugorokuProgress) {
                throw new Error('no account sugoroku progress returned');
            }
            setAllPlayers(player.length);

            // ここで消しておかないと読み込むたびにドーレくんがピョンピョンしちゃうので
            SessionStorageManager.deleteSessionStorageValue(
                SESSION_STORAGE_KEYS.MY_PAGE_SUGOROKU_REMAIN_DICE_VALUE
            );
            const daysRemaining = calculateDaysRemaining(
                mapEvents?.sugorokuMap?.endDatetime
            );
            setDaysRemaining(daysRemaining);

            const availablePlayers = calculateAvailablePlayers(
                accountSugorokuProgress
            );
            setAvailablePlayers(availablePlayers);

            const traversedCells =
                accountSugorokuProgress.total_cells_traversed;
            setTilePosition(traversedCells - 1 - remainDiceValueNumber);
            const stageAreaName = await fetchStageAreaName(
                accountSugorokuProgress,
                mapEvents
            );
            setStageAreaName(stageAreaName);

            const diceCount = SugorokuItemManager.getDiceCount(
                accountSugorokuProgress
            );
            setDiceCount(diceCount);

            const nextStageEvent = findNextStageEvent(
                accountSugorokuProgress,
                mapEvents
            );
            // TS用のおまじない
            if (!nextStageEvent) {
                return;
            }
            const nextEventName = nextStageEvent.title;
            setNextEventName(nextEventName);

            const remainingTilesInEvent = findRemainingTilesInEvent(
                accountSugorokuProgress,
                mapEvents
            );

            if (typeof remainingTilesInEvent === 'number') {
                setRemainingEventTiles(remainingTilesInEvent);
            } else {
                setRemainingEventTiles(0);
            }

            const nextEventItem = nextStageEvent.item;
            setNextEventItem(nextEventItem);

            const cellsToGoalValue = getCellsToGoal(accountSugorokuProgress);
            if (cellsToGoalValue > 0) {
                setCellsToGoal(cellsToGoalValue - remainDiceValueNumber);
            }

            const stageGoal = findStageGoal(
                accountSugorokuProgress,
                nextStageEvent
            );

            setTotalCells(cellsToGoalValue + traversedCells);

            const isFinished = accountSugorokuProgress.is_finished;
            setIsFinished(isFinished);

            // 現在のtilePositionがステージベントマスと一致するかチェック
            // 余り出目がある場合はこのチェックをスキップし、FullTilePath側でsetShowPkDialogさせる。
            if (remainDiceValueNumber === 0 && stageGoal === 0 && !isFinished) {
                setShowPkDialog(true);
            }

            if (isFinished) {
                return;
            }
            // 余り出目分進ませる対応
            if (remainDiceValueNumber > 0) {
                setDiceValue(remainDiceValueNumber);
                setTilePositionChange(remainDiceValueNumber);
            }
        } catch (error: unknown) {
            setSugorokuMap(null);
            SugorokuMasterManager.clearSugorokuMaster();
            console.error('Failed to fetch sugoroku master:', error);
            handleAxiosError(logout, error);
        } finally {
            //FullTilePathのisTileBeforeLastの値をセットなるのが遅くて、
            //ドーレくんのローディング前になるはずの移動は画面で見えちゃうため、それを防ぐためローディング100ミリセック長くセット;
            setTimeout(() => {
                setShowLoadingSpinner(false);
            }, 100);
        }
    };

    const fetchSugorokuRanking = async () => {
        try {
            const rankingData = await getSugorokuRanking();
            if (rankingData.current && rankingData.current.user) {
                setCurrentRank(rankingData.current.user.rank);
                setTotalPlayers(rankingData.current.user.total_players);
                if (rankingData.current.top) {
                    setCurrentTopRankings(rankingData.current.top);
                }
            }
            if (rankingData.past) {
                setPastRankings(rankingData.past);
            }
            if (rankingData.season) {
                setSeasonData(rankingData.season);
            }
        } catch (error) {
            console.error('ランキング取得に失敗しました:', error);
        }
    };

    useEffect(() => {
        const fetchAndHandleEvents = async () => {
            if (!isFinished && !showPkDialog && diceCount === 0) {
                const todayEvents = await getEvents();
                const event = todayEvents[0];
                setEvent(event);
                if (event && !event.has_checked_in) {
                    setShowCheckinDialog(true);
                    return;
                } else {
                    const eventNameArray = await fetchReceiveProbability();
                    if (eventNameArray.length !== 0) {
                        setEventNames(eventNameArray);
                        setShowRegisterDialog(true);
                    } else {
                        setShowCheckinDialog(false);
                        setShowRegisterDialog(false);
                    }
                }
            }
        };
        if (initialized) {
            fetchAndHandleEvents();
        }
    }, [isFinished, initialized, diceCount, showPkDialog]);

    /**
     * すごろく開始期間外のメッセージ
     * ※ 都度変更すること
     */
    const StartDateMessage: React.FC = () => {
        return (
            <StartDateMessageContainer>
                <StartDateMessageMainText>
                    次回CONFTすごろくは
                    <br />
                    2025シーズンHOME開幕戦より開催
                </StartDateMessageMainText>
            </StartDateMessageContainer>
        );
    };

    /**
     * すごろくを環境変数で停止している場合のメッセージ
     */
    const SugorokuDisabledMessage: React.FC = () => {
        return (
            <SugorokuDisabledMessageContainer>
                誠に申し訳ございません。
                <br />
                <br />
                ただいま問題が発生したため
                <br />
                すごろくゲームを停止しております。
                <br />
                <br />
                復旧までお待ちください。
            </SugorokuDisabledMessageContainer>
        );
    };

    return (
        <>
            {hasError && <SugorokuErrorDialog />}
            {showLoadingSpinner && <LoadingSpinnerOverlay opacity={1} />}
            {showDiceRollLoadingSpinner && (
                <LoadingSpinnerOverlay opacity={0.6} />
            )}
            {showPkDialog && (
                <PkDialog
                    nextEventItem={nextEventItem}
                    handlePkDialogButtonClick={handlePkDialogButtonClick}
                />
            )}
            {!showPkDialog &&
                !showPkScreen &&
                !showDice &&
                !showHelpPage &&
                tilePositionChange === 0 &&
                showCheckinDialog && <CheckinDialog event={event} />}
            {!showPkDialog &&
                !showPkScreen &&
                !showDice &&
                !showHelpPage &&
                tilePositionChange === 0 &&
                showRegisterDialog && (
                    <RegisterDialog eventNames={eventNames} />
                )}
            {!showPkDialog && !showPkScreen && showDice && (
                <DiceRoll
                    handleRoll={handleDiceRollComplete}
                    diceValue={diceValue}
                    isRollingDice={isRollingDice}
                    setIsRollingDice={setIsRollingDice}
                />
            )}
            {showPkScreen && nextEventItem ? (
                <PkScreen nextEventItem={nextEventItem} />
            ) : showHelpPage ? (
                <SugorokuHelp />
            ) : (
                <Container>
                    <InfoIcon
                        $ableToPush={
                            !isSugorokuDisabled &&
                            !showDice &&
                            tilePositionChange === 0
                        }
                        src={infoIcon}
                        onClick={handleHelpIconClick}
                        alt="infoIcon"
                    />
                    <SkyBackground />
                    <CloudsOverlay />
                    <SecondCloudOverlay />
                    {isSugorokuDisabled ? (
                        <SugorokuDisabledMessage />
                    ) : (
                        !isSugorokuStarted && <StartDateMessage />
                    )}
                    <GreenaryBackground>
                        <TilePathAndFootballContainer>
                            {!isSugorokuDisabled && isSugorokuStarted && (
                                <>
                                    <FullTilePath
                                        numTiles={totalCells}
                                        cellsToGoalValue={cellsToGoal}
                                        tilePosition={tilePosition}
                                        diceValue={diceValue}
                                        isRollingDice={isRollingDice}
                                        setTilePosition={setTilePosition}
                                        setRemainingEventTiles={
                                            setRemainingEventTiles
                                        }
                                        tilePositionChange={tilePositionChange}
                                        setTileChangePositionChange={
                                            setTilePositionChange
                                        }
                                        showPkDialog={showPkDialog}
                                        setShowPkDialog={setShowPkDialog}
                                        sugorokuMaster={SugorokuMasterManager.getMap()}
                                        clubPlayers={SugorokuMasterManager.getClubPlayers()}
                                        isFinished={isFinished}
                                        onRemainingTilesChange={
                                            handleRemainingTilesChange
                                        }
                                        fetchSugorokuRanking={
                                            fetchSugorokuRanking
                                        }
                                    />
                                    <FootballImage
                                        src={Football}
                                        alt="Football"
                                    />
                                </>
                            )}
                        </TilePathAndFootballContainer>
                    </GreenaryBackground>
                    <MainContentContainer>
                        <CircularRectangleBoxContainer>
                            <DynamicText
                                daysRemaining={daysRemaining}
                                isSugorokuStarted={isSugorokuStarted}
                                sugorokuMaster={sugorokuMap}
                            />
                        </CircularRectangleBoxContainer>
                        <SugorokuRankingInfo
                            currentRank={currentRank || undefined}
                            totalPlayers={totalPlayers}
                            traversedCells={calculateTraversedCells(
                                totalCells,
                                tilePosition,
                                remainingTiles
                            )}
                            pastRankings={pastRankings}
                            seasonData={seasonData}
                            currentTopRankings={currentTopRankings}
                        />
                        <PlayerListButtonComponent
                            $ableToPush={
                                !showDice &&
                                !isSugorokuDisabled &&
                                tilePositionChange === 0
                            }
                            navigate={navigate}
                            availablePlayers={AvailablePlayers}
                            allPlayers={AllPlayers}
                            isSugorokuStarted={isSugorokuStarted}
                            isSugorokuDisabled={isSugorokuDisabled}
                        />
                    </MainContentContainer>
                    <SaikoroContainerComponent
                        isSugorokuStarted={isSugorokuStarted}
                        diceCount={diceCount}
                        isFinished={isFinished}
                        traversedCells={calculateTraversedCells(
                            totalCells,
                            tilePosition,
                            remainingTiles
                        )}
                        sugorokuMaster={sugorokuMap}
                    />
                    <RoundButton
                        onClick={handleButtonClick}
                        $ableToPush={
                            !isFinished &&
                            isSugorokuStarted &&
                            !isSugorokuDisabled &&
                            diceCount !== null &&
                            diceCount > 0 &&
                            !showDice &&
                            !showPkDialog &&
                            tilePositionChange === 0 // PKの余り出目分進んだ場合の対策
                        }
                    >
                        <DiceImage src={diceSvg} alt="dice" />
                        <Furu src={furu} alt="furu" />
                    </RoundButton>
                </Container>
            )}
        </>
    );
};

export default MyPageSugoroku;
