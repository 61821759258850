import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { IoInformationCircleOutline } from 'react-icons/io5';

const RankingInfoButton = styled.button`
    /* display: flex; */
    align-items: center;
    padding: 0;
    margin-left: 4px;
    line-height: 1;
    color: #000000;
    cursor: pointer;
    background: none;
    border: none;

    &:hover {
        opacity: 0.8;
    }
`;

const PopupOverlay = styled.div`
    position: fixed;
    inset: 0;
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 50%);
`;

const PopupContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 300px;
    height: auto;
    max-height: 350px;
    overflow: hidden;
    font-family: 'Noto Sans CJK JP', sans-serif;
    font-weight: 900;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
`;

const PopupHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    font-size: 18px;
    font-weight: 900;
    color: white;
    background-color: #e40513;
`;

const PopupCloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;
    font-size: 24px;
    color: white;
    cursor: pointer;
    background: none;
    border: none;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
`;

const RankingHistory = styled.div`
    flex: 1;
    padding: 16px;
    overflow-y: auto;

    /* スクロールバーのスタイル */
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #cccccc;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #aaaaaa;
    }
`;

const RankingList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 8px 0 0;
    margin: 0;
    list-style: none;
`;

const RankingItem = styled.li`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px;
    background-color: #f8f8f8;
    border: 1px solid #eeeeee;
    border-radius: 8px;
`;

const RankingPeriod = styled.span`
    font-size: 13px;
    line-height: 1.4;
    color: #666666;
`;

const RankingPosition = styled.div`
    font-family: 'Noto Sans CJK JP', sans-serif;
    font-size: 22px;
    font-weight: bold;
    color: #e40513;
    text-align: right;
`;

const RankingTitle = styled.h3`
    padding: 4px;
    margin: 0;
    font-size: 16px;
    font-weight: 900;
    text-align: left;
    border-bottom: 2px solid #666666;
`;

const MiniText = styled.div`
    margin-top: -8px;
    margin-bottom: 4px;
    font-size: 10px;
    color: #666666;
    text-align: right;
`;

interface RankingHistoryButtonProps {
    className?: string;
    pastRankings?: {
        sugoroku_map: string;
        rank: number | null;
        total_players: number;
    }[];
    currentRanking?: {
        sugoroku_map: string;
        rank: number | null;
        total_players: number;
    }[];
    seasonData?: {
        season_id: number;
        season_name: string;
        progress: {
            total_cells: number;
            rank: number;
        };
    }[];
    currentTopRankings?: {
        rank: number;
        total_cell: number;
    }[];
    traversedCells?: number;
}

export const RankingHistoryButton: React.FC<RankingHistoryButtonProps> = ({
    className,
    pastRankings,
    currentRanking,
    seasonData,
    currentTopRankings,
    traversedCells,
}) => {
    const [showRankingPopup, setShowRankingPopup] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date());

    // 同率順位を計算し、自分のマス数と同じ場合は「あなた」と表示するフラグを追加
    const processedTopRankings = React.useMemo(() => {
        if (!currentTopRankings) return [];

        let hasShownYou = false;

        return currentTopRankings.map((ranking, index, array) => ({
            ...ranking,
            displayRank:
                index > 0 && array[index - 1].total_cell === ranking.total_cell
                    ? array[index - 1].rank
                    : ranking.rank,
            isYou:
                traversedCells === ranking.total_cell &&
                !hasShownYou &&
                (hasShownYou = true),
        }));
    }, [currentTopRankings, traversedCells]);

    useEffect(() => {
        // ポップアップが表示されている時だけ時刻を更新
        if (showRankingPopup) {
            const timer = setInterval(() => {
                setCurrentTime(new Date());
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [showRankingPopup]);

    const formatDateTime = (date: Date) => {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `※${month}月${day}日${hours}時${minutes}分時点`;
    };

    /**
     * TODO: 暫定対応
     * sugoroku_mapの文字列に「2024」が含まれているかどうかで年間/月間を判定
     * 将来的にはAPIのレスポンスに年間/月間の判定フラグを追加する必要あり
     */
    const isYearlyRanking = (sugorokuMap: string): boolean => {
        return sugorokuMap.includes('2024');
    };

    // 月間ランキングと年間ランキングを分離
    const monthlyRankings = [
        ...(currentRanking || []),
        ...(pastRankings?.filter(
            (ranking) => !isYearlyRanking(ranking.sugoroku_map)
        ) || []),
    ];
    const yearlyRankings = [
        ...(currentRanking?.filter((ranking) =>
            isYearlyRanking(ranking.sugoroku_map)
        ) || []),
        ...(pastRankings?.filter((ranking) =>
            isYearlyRanking(ranking.sugoroku_map)
        ) || []),
    ];

    return (
        <>
            <RankingInfoButton
                className={className}
                onClick={() => setShowRankingPopup(true)}
            >
                <IoInformationCircleOutline size={20} />
            </RankingInfoButton>
            {showRankingPopup &&
                createPortal(
                    <PopupOverlay onClick={() => setShowRankingPopup(false)}>
                        <PopupContent onClick={(e) => e.stopPropagation()}>
                            <PopupHeader>
                                すごろくデータ
                                <PopupCloseButton
                                    onClick={() => setShowRankingPopup(false)}
                                >
                                    ×
                                </PopupCloseButton>
                            </PopupHeader>
                            <RankingHistory>
                                <RankingList>
                                    {currentTopRankings &&
                                        currentTopRankings.length > 0 && (
                                            <>
                                                <RankingTitle>
                                                    今月の上位ユーザーランキング
                                                </RankingTitle>
                                                <MiniText>
                                                    {formatDateTime(
                                                        currentTime
                                                    )}
                                                </MiniText>
                                                {processedTopRankings.map(
                                                    (ranking, index) => (
                                                        <React.Fragment
                                                            key={`${ranking.displayRank}-${index}`}
                                                        >
                                                            <RankingItem>
                                                                <RankingPeriod>
                                                                    {
                                                                        ranking.displayRank
                                                                    }
                                                                    位
                                                                    {ranking.isYou &&
                                                                        ' (あなた)'}
                                                                </RankingPeriod>
                                                                <RankingPosition>
                                                                    {
                                                                        ranking.total_cell
                                                                    }
                                                                    マス
                                                                </RankingPosition>
                                                            </RankingItem>
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </>
                                        )}
                                    {seasonData && seasonData.length > 0 && (
                                        <>
                                            {seasonData.map((season) => (
                                                <React.Fragment
                                                    key={season.season_id}
                                                >
                                                    <RankingTitle>
                                                        ({season.season_name})
                                                        あなたのデータ
                                                    </RankingTitle>
                                                    <RankingItem>
                                                        <RankingPeriod>
                                                            {season.season_name}
                                                            通年ランキング
                                                        </RankingPeriod>
                                                        <RankingPosition>
                                                            {
                                                                season.progress
                                                                    .rank
                                                            }
                                                            位
                                                        </RankingPosition>
                                                    </RankingItem>
                                                    <RankingItem>
                                                        <RankingPeriod>
                                                            {season.season_name}
                                                            に進んだマス数
                                                        </RankingPeriod>
                                                        <RankingPosition>
                                                            {
                                                                season.progress
                                                                    .total_cells
                                                            }
                                                            マス
                                                        </RankingPosition>
                                                    </RankingItem>
                                                </React.Fragment>
                                            ))}
                                        </>
                                    )}
                                    {monthlyRankings &&
                                        monthlyRankings.length > 0 && (
                                            <>
                                                <RankingTitle>
                                                    過去のすごろくランキング(月間)
                                                </RankingTitle>
                                                {monthlyRankings.map(
                                                    (ranking, index) => (
                                                        <RankingItem
                                                            key={index}
                                                        >
                                                            <RankingPeriod>
                                                                {
                                                                    ranking.sugoroku_map
                                                                }
                                                            </RankingPeriod>
                                                            <RankingPosition>
                                                                {ranking.rank
                                                                    ? `${ranking.rank}位`
                                                                    : '-'}
                                                            </RankingPosition>
                                                        </RankingItem>
                                                    )
                                                )}
                                            </>
                                        )}
                                    {yearlyRankings &&
                                        yearlyRankings.length > 0 && (
                                            <>
                                                <RankingTitle>
                                                    過去のすごろくランキング(年間)
                                                </RankingTitle>
                                                {yearlyRankings.map(
                                                    (ranking, index) => (
                                                        <RankingItem
                                                            key={index}
                                                        >
                                                            <RankingPeriod>
                                                                {
                                                                    ranking.sugoroku_map
                                                                }
                                                            </RankingPeriod>
                                                            <RankingPosition>
                                                                {ranking.rank
                                                                    ? `${ranking.rank}位`
                                                                    : '-'}
                                                            </RankingPosition>
                                                        </RankingItem>
                                                    )
                                                )}
                                            </>
                                        )}
                                </RankingList>
                            </RankingHistory>
                        </PopupContent>
                    </PopupOverlay>,
                    document.body
                )}
        </>
    );
};

export default RankingHistoryButton;
