import React from 'react';
import styled from 'styled-components';
import { SugorokuMaster } from '../../types/sugorokuMasterType';

interface DynamicTextProps {
    daysRemaining: number;
    isSugorokuStarted: boolean;
    sugorokuMaster: SugorokuMaster | null;
}
const ConftTitleContainer = styled.div`
    position: absolute;
    top: 45%;
    left: 53%;
    z-index: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75vw;
    font-weight: 900;
    color: black;
    text-align: center;
    transform: translate(-50%, -50%);

    @media (width >= 360px) {
        left: 170px;
        width: 314px;
    }
`;

const MainTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MainTitleText = styled.span`
    margin-right: 4px;
    font-size: 5.3vw;
    font-weight: 900;
    white-space: nowrap;

    @media (width >= 360px) {
        font-size: 24px;
    }
`;

const RemainingDaysContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
    margin-top: 2px;
`;

const RemainingDaysText = styled.span`
    font-size: 12px;
    font-weight: 900;
`;

const DynamicText: React.FC<DynamicTextProps> = ({ sugorokuMaster }) => {
    const calculatePeriodAndDateRange = () => {
        if (!sugorokuMaster?.sugorokuMap) {
            return { period: '-', dateRange: '-' };
        }

        const startDate = new Date(sugorokuMaster.sugorokuMap.startDatetime);
        const endDate = new Date(sugorokuMaster.sugorokuMap.endDatetime);

        const period = (startDate.getMonth() + 1).toString();
        const dateRange = `${startDate.getMonth() + 1}/${startDate.getDate()}～${endDate.getMonth() + 1}/${endDate.getDate()}`;

        return { period, dateRange };
    };

    const { period, dateRange } = calculatePeriodAndDateRange();

    return (
        <ConftTitleContainer>
            <MainTitleContainer>
                <MainTitleText>CONFTすごろく</MainTitleText>
            </MainTitleContainer>
            <RemainingDaysContainer>
                <RemainingDaysText>{period}月期</RemainingDaysText>
                <RemainingDaysText>{dateRange}</RemainingDaysText>
            </RemainingDaysContainer>
        </ConftTitleContainer>
    );
};

export default DynamicText;
